import  React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Aqp from '../../../libs/mk-react-aqp';
import moment from "moment-timezone";

import {PageLoader, SpinLoader, LoadingBar} from "../../components/Loader";
import {Button, Col, Form, Row, Table} from "react-bootstrap";
import FormFields from "../../components/FormFields";

import {fetchTransactionStats} from "../../../creators/transaction.stats";
import {fetchLocationCountries} from "../../../creators/locations.countries";
import {fetchOffers} from "../../../creators/offers";
import ColoneTn from "../../components/ColoneTh";
import {fetchAbTestings} from "../../../creators/abTestings";



let mapping = {
    "sort" : "trie",
    "dateStart" : "start",
    "dateEnd" : "fin"
};

class PageStatsTransaction extends React.Component {
    constructor(props) {
        super(props);

        this.groupby = [
            { "value" : "day", label: "Jour" },
            { "value" : "mouth", label: "Mois" }
        ];
        this.processeur = [
            { "value" : "", label: "All" },
            { "value" : "paysitecash", label: "PaysiteCash" },
            { "value" : "securionpay", label: "SecurionPay" },
            { "value" : "mobiyo", label: "Mobiyo" },
            { "value" : "neosurf", label: "Neosurf" },
            { "value" : "vendo", label: "Vendo" },
            { "value" : "monerchy", label: "Monerchy" }
        ];

        this.totalAllInitial = {
            countTrial : 0,
            amountTrial : 0,
            countBill : 0,
            amountBill : 0,
            countRebill : 0,
            amountRebill : 0,
            countCancel : 0,
            countChargeback : 0,
            amountChargeback : 0,
            countRefund : 0,
            amountRefund : 0,
            countTotal : 0,
            amountTotal : 0
        };

        this.totalAll = this.totalAllInitial;

        this.filter = {
            limit: 18,
            page: 1,
            sort: "createdAt",
            groupby : "day",
            offer : "",
            abTestingId : "",
            processeur : "",
            country : "",
            dateStart : moment().subtract(1,'months').format("YYYY-MM-DD"),
            dateEnd : moment().format("YYYY-MM-DD"),
        };

        this.aqp = new Aqp(mapping, {
            blacklist: ['limit', 'page'],
            currentLink: "/stats",
            filterDefault: this.filter
        });

        this.state = {
            filter: {...this.filter, ...this.aqp.query, page: this.props.match.params.page || 1}
        };
        this.props.fetchLocationCountries();
        this.props.fetchOffers();
        this.props.fetchAbTestings();

        this.submitAccepted = this.submitAccepted.bind(this);
        this.submitAccepted();
    }

    submitAccepted(){
        let filter = {...this.state.filter};
        if(filter.offer === ""){
            delete filter.offer;
        }
        if(filter.processeur === ""){
            delete filter.processeur;
        }
        if(filter.country === ""){
            delete filter.country;
        }
        if(filter.abTestingId === ""){
            delete filter.abTestingId;
        }
        filter.dateStart = moment(filter.dateStart).format("YYYY-MM-DD");
        filter.dateEnd = moment(filter.dateEnd).format("YYYY-MM-DD");
        this.props.fetchTransactionStats(this.aqp.set(filter));
    }

    render() {
        // this.totalAll = this.totalAllInitial;
        return (!this.props.statsTransactionInitialized) ? (<PageLoader/>) : (
            <Fragment>
                <Form.Row>
                    <FormFields
                        group={{controlId : "dateStart", className : "col-2"}}
                        type={"date"}
                        label={{
                            label : "Date debut"
                        }}
                        controle={{
                            name : "dateStart",
                            value : this.state.filter.dateStart,
                            onChange : (date) =>{this.setState({filter : {...this.state.filter,dateStart : moment(date).format("YYYY-MM-DD")}})},
                            className : "w-100"
                        }}

                    />
                    <FormFields
                        group={{controlId : "dateEnd", className : "col-2"}}
                        type={"date"}
                        label={{
                            label : "Date Fin"
                        }}
                        controle={{
                            name : "dateEnd",
                            value : this.state.filter.dateEnd,
                            onChange : (date) =>{this.setState({filter : {...this.state.filter,dateEnd : moment(date).format("YYYY-MM-DD")}})},
                            className : "w-100"
                        }}
                    />
                    <FormFields
                        group={{controlId : "groupby", className : "col-1"}}
                        type={"select"}
                        label={{
                            label : "Period"
                        }}
                        controle={{
                            name : "groupby",
                            value : this.state.filter.groupby,
                            options : this.groupby,
                            onChange : (event) =>{this.setState({filter : {...this.state.filter,groupby : event.target.value}})},
                            className : "w-100"
                        }}
                    />
                    <FormFields
                        initialized={this.props.countriesLoading}
                        group={{controlId : "country", className : "col-2"}}
                        type={"select"}
                        label={{
                            label : "Pays"
                        }}
                        controle={{
                            name : "country",
                            value : this.state.filter.country,
                            options : this.props.countries,
                            disabled : this.props.countries.length ? false : true,
                            optionField : {value : "code", label : "name"},
                            onChange : (event) =>{this.setState({filter : {...this.state.filter,country : event.target.value}})},
                            className : "w-100"
                        }}
                    />
                    <FormFields
                        group={{controlId : "processeur", className : "col-1"}}
                        type={"select"}
                        label={{
                            label : "Biller"
                        }}
                        controle={{
                            name : "processeur",
                            value : this.state.filter.processeur,
                            options : this.processeur,
                            onChange : (event) =>{this.setState({filter : {...this.state.filter,processeur : event.target.value}})},
                            className : "w-100"
                        }}
                    />
                    <FormFields
                        initialized={this.props.offersLoading}
                        group={{controlId : "offer", className : "col-2"}}
                        type={"select"}
                        label={{
                            label : "Offre"
                        }}
                        controle={{
                            name : "offer",
                            value : this.state.filter.offer,
                            options : this.props.offers,
                            disabled : this.props.offers.length ? false : true,
                            optionField : {value : "_id", label : "name"},
                            onChange : (event) =>{this.setState({filter : {...this.state.filter,offer : event.target.value}})},
                            className : "w-100"
                        }}
                    />
                    <FormFields
                        initialized={this.props.abTestingsLoading}
                        group={{controlId : "abTestings", className : "col-2"}}
                        type={"select"}
                        label={{
                            label : "abTestingId"
                        }}
                        controle={{
                            name : "abTestingId",
                            value : this.state.filter.abTestingId,
                            options : this.props.abTestings,
                            disabled : this.props.abTestings.length ? false : true,
                            optionField : {value : "_id", label : "title"},
                            onChange : (event) =>{this.setState({filter : {...this.state.filter,abTestingId : event.target.value}})},
                            className : "w-100"
                        }}
                    />
                    <Col className={"form-group flex justify-content-md-center"}>
                    <Button variant="primary" className={"btn-loading mt-3"} onClick={() => this.submitAccepted()}>
                        Valider {this.props.statsTransactionLoading && (
                        <div className={"pl-1"}><SpinLoader className={"xsmall"}/></div>)}
                    </Button>
                    </Col>
                </Form.Row>

                <Row>
                    {this.props.statsTransactionLoading ? (<LoadingBar/>) : (
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <ColoneTn label={"Date"} className={"min-w-120"}  />
                                <ColoneTn label={"N° Trial"}  />
                                <ColoneTn label={"Montant Trial"}  />
                                <ColoneTn label={"N° Bill"}  />
                                <ColoneTn label={"Montant Bill"}  />
                                <ColoneTn label={"N° Rebill"}  />
                                <ColoneTn label={"Montant Rebill"}  />
                                <ColoneTn label={"N° Cancel"}  />
                                <ColoneTn label={"N° Chargeback"}  />
                                <ColoneTn label={"Montant Chargeback"}  />
                                <ColoneTn label={"N° Refund"}  />
                                <ColoneTn label={"Montant Refund"}  />
                                <ColoneTn label={"N° Total"}  />
                                <ColoneTn label={"Montant Total"}  />
                            </tr>
                            </thead>
                            <tbody>
                            {this.props.statsTransaction.map((stat, index) =>{
                                let amountTotal = (stat.amountBill + stat.amountRebill - stat.amountChargeback - stat.amountRefund);
                                if(index === 0){
                                    this.totalAll = this.totalAllInitial;
                                }
                                this.totalAll = {
                                    countTrial : this.totalAll.countTrial + stat.countTrial,
                                    amountTrial : this.totalAll.amountTrial + stat.amountTrial,
                                    countBill : this.totalAll.countBill + stat.countBill,
                                    amountBill : this.totalAll.amountBill + stat.amountBill,
                                    countRebill : this.totalAll.countRebill + stat.countRebill,
                                    amountRebill : this.totalAll.amountRebill + stat.amountRebill,
                                    countCancel : this.totalAll.countCancel + stat.countCancel,
                                    countChargeback : this.totalAll.countChargeback + stat.countChargeback,
                                    amountChargeback : this.totalAll.amountChargeback + stat.amountChargeback,
                                    countRefund : this.totalAll.countRefund + stat.countRefund,
                                    amountRefund : this.totalAll.amountRefund + stat.amountRefund,
                                    countTotal : this.totalAll.countTotal + stat.countTotal,
                                    amountTotal : this.totalAll.amountTotal + amountTotal,
                                };
                                return (
                                    <tr key={stat.createdAt}>
                                        <td>{stat._id.dayOfMonth ? moment.utc(stat.createdAt).format("DD-MM-YYYY") : moment.utc(stat.createdAt).endOf('day').format("MM-YYYY")}</td>
                                        <td>{stat.countTrial}</td>
                                        <td>{stat.amountTrial.toFixed(2)} €</td>
                                        <td>{stat.countBill}</td>
                                        <td>{stat.amountBill.toFixed(2)} €</td>
                                        <td>{stat.countRebill}</td>
                                        <td>{stat.amountRebill.toFixed(2)} €</td>
                                        <td>{stat.countCancel}</td>
                                        <td>{stat.countChargeback}</td>
                                        <td>{stat.amountChargeback.toFixed(2)} €</td>
                                        <td>{stat.countRefund}</td>
                                        <td>{stat.amountRefund.toFixed(2)} €</td>
                                        <td>{stat.countTotal}</td>
                                        <td>{amountTotal.toFixed(2)} €</td>
                                    </tr>
                                )
                            })}
                            <tr key="total">
                                <td>Total</td>
                                <td>{this.totalAll.countTrial}</td>
                                <td>{this.totalAll.amountTrial.toFixed(2)} €</td>
                                <td>{this.totalAll.countBill}</td>
                                <td>{this.totalAll.amountBill.toFixed(2)} €</td>
                                <td>{this.totalAll.countRebill}</td>
                                <td>{this.totalAll.amountRebill.toFixed(2)} €</td>
                                <td>{this.totalAll.countCancel}</td>
                                <td>{this.totalAll.countChargeback}</td>
                                <td>{this.totalAll.amountChargeback.toFixed(2)} €</td>
                                <td>{this.totalAll.countRefund}</td>
                                <td>{this.totalAll.amountRefund.toFixed(2)} €</td>
                                <td>{this.totalAll.countTotal}</td>
                                <td>{this.totalAll.amountTotal.toFixed(2)} €</td>
                            </tr>
                            </tbody>
                        </Table>
                    )}
                </Row>
            </Fragment>

        )
    }
}


const mapStateToProps = (state) => {
    let countries = [...state.locationCountries.data];
    countries.unshift({_id : "", code : "", name : "Aucun"});
    let offers = [...state.offers.data];
    offers.unshift({_id : "", name : "all"});
    let abTestings = [...state.abTestings.data];
    abTestings.unshift({_id : "", title : "all"});
    return {
        statsTransaction : state.transactionStats.data,
        statsTransactionTotal : state.transactionStats.total,
        statsTransactionLoading : state.transactionStats.loading,
        statsTransactionInitialized : state.transactionStats.initialized,

        countries,
        countriesInitialized: state.locationCountries.initialized,
        countriesLoading: !state.locationCountries.loading,

        offers,
        offersInitialized: state.offers.initialized,
        offersLoading: !state.offers.loading,

        abTestings,
        abTestingsInitialized: state.abTestings.initialized,
        abTestingsLoading: !state.abTestings.loading,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchTransactionStats,
    fetchLocationCountries,
    fetchOffers,
    fetchAbTestings
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PageStatsTransaction);