import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Row, Table} from "react-bootstrap";
import {fetchUserTransactions, resetUserTransactions} from "../../../creators/user.transactions";
import {LoadingBar} from "../../components/Loader";
import ColoneTn from "../../components/ColoneTh";
import moment from "moment-timezone";


class Transaction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };

        this.props.fetchUserTransactions(this.props.user._id, {sort : "-createdAt", limit : 1000});
    }

    componentWillUnmount() {
        this.props.resetUserTransactions();
    }


    render() {
        return (
            <Fragment>
                <Row className={"mt-5 p-2"}>
                    <h4 className={"w-100"}>Transactions</h4>
                        {this.props.transactionsLoading ? (<LoadingBar/>) : (
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <ColoneTn label={"Date"}  />
                                    <ColoneTn label={"Type"}  />
                                    <ColoneTn label={"Offre"}  />
                                    <ColoneTn label={"Montant"}  />
                                </tr>
                                </thead>
                                <tbody>
                                {this.props.transactions.map(stat =>{
                                    if(stat.processeur === "paysitecash"){
                                        return(
                                            <tr key={stat.createdAt}>
                                                <td>{moment(stat.createdAt).format("DD-MM-YYYY HH:mm")}</td>
                                                <td>{stat.typeName}</td>
                                                <td>{(stat.offer && stat.offer.name) || ""}</td>
                                                <td>{stat.amount} {stat.currency}</td>
                                            </tr>
                                        )
                                    }
                                    else if(stat.processeur === "securionpay"){
                                        let amount = stat.data_charge_amount >0 ? stat.data_charge_amount / 100 : 0;
                                        let currency = stat.data_card_fraud_country;

                                        if(stat.data_event_type === "CHARGE_DISPUTE_FUNDS_WITHDRAWN"){
                                            amount = stat.data_charge_dispute_amount >0 ? stat.data_charge_dispute_amount / 100 : 0;
                                            currency = stat.data_charge_dispute_currency;
                                        }
                                        else if(stat.data_event_type === "CHARGE_REFUNDED"){
                                            amount = stat.data_charge_refund_amount >0 ? stat.data_charge_refund_amount / 100 : 0;
                                            currency = stat.data_charge_refund_currency;
                                        }
                                        return(
                                            <tr key={stat.createdAt}>
                                                <td>{moment(stat.createdAt).format("DD-MM-YYYY HH:mm")}</td>
                                                <td>{stat.typeName}</td>
                                                <td>{(stat.offer && stat.offer.name) || ""}</td>
                                                <td>{amount} {currency}</td>
                                            </tr>
                                        )
                                    }
                                    else if(stat.processeur === "mobiyo"){
                                        return(
                                            <tr key={stat.createdAt}>
                                                <td>{moment(stat.createdAt).format("DD-MM-YYYY HH:mm")}</td>
                                                <td>{stat.typeName}</td>
                                                <td>{(stat.offer && stat.offer.name) || ""}</td>
                                                <td>{stat.amount} {stat.currency}</td>
                                            </tr>
                                        )
                                    }
                                    else if(stat.processeur === "neosurf"){
                                        return(
                                            <tr key={stat.createdAt}>
                                                <td>{moment(stat.createdAt).format("DD-MM-YYYY HH:mm")}</td>
                                                <td>{stat.typeName}</td>
                                                <td>{(stat.offer && stat.offer.name) || ""}</td>
                                                <td>{stat.amount} {stat.currency}</td>
                                            </tr>
                                        )
                                    }
                                    else if(stat.processeur === "vendo"){
                                        return(
                                            <tr key={stat.createdAt}>
                                                <td>{moment(stat.createdAt).format("DD-MM-YYYY HH:mm")}</td>
                                                <td>{stat.typeName}</td>
                                                <td>{(stat.offer && stat.offer.name) || ""}</td>
                                                <td>{stat.invoice_amount} {stat.invoice_currency}</td>
                                            </tr>
                                        )
                                    }
                                    else if(stat.processeur === "monerchy"){
                                        return(
                                            <tr key={stat.createdAt}>
                                                <td>{moment(stat.createdAt).format("DD-MM-YYYY HH:mm")}</td>
                                                <td>{stat.typeName}</td>
                                                <td>{(stat.offer && stat.offer.name) || ""}</td>
                                                <td>{stat.totalAmount} {stat.currency}</td>
                                            </tr>
                                        )
                                    }
                                    else{
                                        return null;
                                    }

                                })}
                                </tbody>
                            </Table>
                        )}
                </Row>
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        transactions : state.transactions.data,
        transactionsInitialized: state.transactions.initialized,
        transactionsLoading: state.transactions.loading,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUserTransactions,
    resetUserTransactions
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
