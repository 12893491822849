import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('OFFERS').plugin(axiosPlugin, {template: "array"});

let fetchOffersAction = redux.fetchActionGet();

export const fetchOffers = (query = {}) => fetchOffersAction(`/offers/admin-all?${queryString.stringify(query)}`);
export const resetOffers = () => redux.actions.reset();

export const offers = redux.reducer();