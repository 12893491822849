import React from 'react';
import moment from "moment-timezone";
import DatePicker from 'react-date-picker';
import {Form, InputGroup} from "react-bootstrap";
import {LoadingBar} from "./Loader";

const FormFields = props => {
    let initialControle = {
        type : "",
        placeholder : "",
        className : "",
        name : "",
        value : "",
        options : [],
        optionField : {
            "value" : "value",
            "label" : "label"
        },
        disabled : false,
        required : false,
        onChange : () =>{},
    };

    let {
        initialized = true,
        group = {
            className : ""
        },
        label = {
            "label" : "",
            "className" : ""
        },
        prepend ={

        },
        type = "input",
        controle = initialControle,
        text = {
            "text" : "",
            "className" : ""
        },
        datePicker = {
            format : "dd/MM/yyyy",
            isOpen : false,
            maxDate : null,
            minDate : null,
            timeInputLabel : "Heure :",
            showTimeInput : false,
            inline : false,
            locale : "fr",
            clearIcon : null,

        },
        checkBox = {
            label : "",
            className : ""
        }
    }  = props;

    controle = {...initialControle,...controle};

    if(type !== "select"){
        delete controle.optionField;
    }


    function onChangeDate(date){
        if(date){
            date = moment(date)._d;
        }
        controle.onChange(date);
    }

    function getDate(date){
        let timeOffset = moment().utcOffset();
        if (timeOffset >= 0) {
            date = moment(date).subtract(timeOffset, 'minutes');

        }
        else {
            date = moment(date).add(timeOffset * -1, 'minutes');
        }

        return date;
    }

    return (

        <Form.Group {...group} >
            {label.label && (
                <Form.Label className={`${label.className} ${controle.isInvalid ? "text-danger" : ""}`}>{label.label}</Form.Label>
            )}

            {type !== "select" && type !== "date" && type !=="checkbox" && controle.type !== "radio" && controle.type !== "" && (
                prepend.id ? <InputGroup ><InputGroup.Prepend>
                    <InputGroup.Text id={prepend.id}>{prepend.content}</InputGroup.Text>
                </InputGroup.Prepend> <Form.Control {...controle} /></InputGroup> : <Form.Control {...controle} />
            )}
            {type === "checkbox" && (
                <Form.Check type="checkbox" {...checkBox} />
            )}
            {type === "radio" && (
                <Form.Check type="radio" {...checkBox} />
            )}
            {type === "select" && !initialized && (<LoadingBar />)}
            {type === "select" && (
                <Form.Control as="select" name={controle.name} value={controle.value} onChange={controle.onChange} className={controle.className} disabled={controle.disabled}
                              required={controle.required}>
                    {controle && controle.options && controle.options.map((item, index) =>(<option key={index} value={item[controle.optionField.value]} {...item.options}>{item[controle.optionField.label]}</option>))}
                </Form.Control>
            )}

            {type === "date" && (
                <DatePicker
                    // selected={moment(controle.value || moment(), moment.defaultFormat).toDate()}
                    value={getDate(controle.value || moment()).toDate()}
                    onChange={(date) =>onChangeDate(date)}

                    {...datePicker}
                    className={controle.className}
                    disabled={controle.disabled}
                    required={controle.required}
                    withPortal/>
            )}

            {text.text && (
                <Form.Text  className={`${text.className}`}>
                    {text.text}
                </Form.Text>
            )}
        </Form.Group>
    )
}

//params Group as={Col},
// params Label = label
// params texte = texte
// params type de controle : input, select , radio,
// params controle = type (email, texte, number ), value , name, options
// error,


export default (FormFields);
