import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {LoadingBar} from "../../components/Loader";
import {fetchAutoLogin, resetAutoLogin} from "../../../creators/user.autoLogin";
import {Row} from "react-bootstrap";
import createLink from "../../../libs/createLink";


class MemberAutoLogin extends React.Component {
    constructor(props) {
        super(props);
        this.currentUserId = this.props.match.params.id || false;
        this.state = {

        }
        if(this.currentUserId) {
            this.props.fetchAutoLogin(this.currentUserId).then(response =>{
                if(response && response.payload && response.payload.tokenAutoLogin){
                    let linkHome = createLink("home");
                    linkHome = `${linkHome}?atoken=${response.payload.tokenAutoLogin}&forced=true`;
                    window.location = linkHome;
                    //window.create({'url': linkHome});
                }
            });
        }
    }
    componentWillUnmount() {
        this.props.resetAutoLogin();
    }


    render() {
        return (
            <Fragment>
                <Row className={"mt-5 p-2"}>
                    <h4 className={"w-100"}>Sessions</h4>
                    {this.props.userSessionsLoading ? (<LoadingBar/>) : null}
                </Row>
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        autologin: state.autologin.data,
        autologinInitialized: state.autologin.initialized,
        autologinLoading: state.autologin.loading,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAutoLogin,
    resetAutoLogin
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MemberAutoLogin);
